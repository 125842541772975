<template>
  <div>
    <v-row>
      <v-col>
        <h2>Incoming Calls</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="start">
      <v-col cols="6">
        <v-card color="primary lighten-1" dark>
          <v-card-title><v-icon left>mdi-alert</v-icon> Note</v-card-title>
          <v-card-text>
            Not needed for external provisioning in the Cloud Softphone. Cloud Softphone providers should contact us
            through the Cloud Softphone web portal if they have any questions on how to set incoming calls.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "incomingCalls"
}
</script>

<style scoped>

</style>